<template>
  <nhom-tieu-chi />
</template>
<script>
import NhomTieuChi from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/danh-gia/NhomTieuChi.vue'

export default {
  components: {
    NhomTieuChi,
  },
}
</script>
