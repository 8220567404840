<template>
  <b-card>
    <action-btn
      :selected-number="selectedRows.length"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @add-item="addCommom"
      @update-item="onUpdate"
      @delete-item="$refs.confirmDelete.showModal()"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="nhomTieuChi"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="danhSachNhomTieuChi"
      :total="tongSoNhomTieuChi"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @selected-item="onSelectRow"
      @column-filter="filterdanhSachNhomTieuChi"
      @update-item="onUpdate"
      @delete-item="setItemDelete"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="filter.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          :limit="0"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn loại trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
          @deselect="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      @handle-ok="onSave"
      @reset-form="resetForm"
      @handle-focus="handleFocusError"
    >
      <b-form>
        <b-form-group
          class="required"
          label="Mã nhóm tiêu chí"
          label-for="maNhomTieuChi"
        >
          <validation-provider
            v-slot="{ errors }"
            key="maNhomTieuChi"
            :rules="{ required: true }"
            name="maNhomTieuChi"
          >
            <b-form-input
              id="maNhomTieuChi"
              ref="maNhomTieuChi"
              v-model="form.maNhomTieuChi"
              :autofocus="form.id ? false : true"
              type="text"
              :class="{required: errors.length > 0}"
              placeholder="Nhập mã nhóm tiêu chí"
            />
            <span class="text-danger">{{ errors[0] }}</span>
            <span class="text-danger">{{ errorMessage }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          class="required"
          label="Tên nhóm tiêu chí"
          label-for="tenNhomTieuChi"
        >
          <validation-provider
            v-slot="{ errors }"
            key="tenNhomTieuChi"
            :rules="{ required: true }"
            name="tenNhomTieuChi"
          >
            <b-form-input
              id="tenNhomTieuChi"
              ref="tenNhomTieuChi"
              v-model="form.tenNhomTieuChi"
              type="text"
              :class="{required: errors.length > 0}"
              placeholder="Nhập tên nhóm tiêu chí"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <div class="d-flex mb-1">
          <span class="mr-3">Trạng thái</span>
          <b-form-checkbox
            v-model="form.status"
            switch
          />
        </div>
        <b-form-group
          label="Mô tả"
          label-for="ghiChu"
        >
          <b-form-textarea
            id="ghiChu"
            v-model="form.ghiChu"
            placeholder="Nhập mô tả"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </b-form>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="confirmDelete"
      title="Xác nhận"
      size="sm"
      @handle-ok="onDelete"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
// eslint-disable-next-line import/named
import _cloneDeep from 'lodash/cloneDeep'
import { compareObjects } from '@/utils'
import { STATUS } from '../../../../../constants/constants'
import { checDeleteMultiple } from '../../../../../utils/common-utils'

export default {
  components: {
    ActionBtn,
    GoodTable,
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    CommonModal,
    ValidationProvider,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      optionsTrangThai: STATUS,
      title: 'Thêm nhóm tiêu chí',
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã nhóm tiêu chí',
          field: 'maNhomTieuChi',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên nhóm tiêu chí',
          field: 'tenNhomTieuChi',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      filter: {
        maNhomTieuChi: '',
        tenNhomTieuChi: '',
        ghiChu: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maNhomTieuChi: '',
        tenNhomTieuChi: '',
        ghiChu: '',
        status: true,
      },
      beginObject: {},
      isShow: false,
      isShowFooter: false,
      selectedRows: [],
      danhSachNhomTieuChi: [],
      tongSoNhomTieuChi: 0,
      errorMessage: '',
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.NHOM_TIEU_CHI.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.NHOM_TIEU_CHI.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.NHOM_TIEU_CHI.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterdanhSachNhomTieuChi()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.form)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    pageChange() {
      const data = this.$refs.nhomTieuChi.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.NHOM_TIEU_CHI.DANH_SACH, payload, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachNhomTieuChi = response.data.data
          this.tongSoNhomTieuChi = response.data.totalCount || 0
        }
      })
    },
    filterdanhSachNhomTieuChi(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.nhomTieuChi) {
        payload = {
          ...payload,
          ...this.$refs.nhomTieuChi.getCurrentPage(),
        }
      }
      if (param.maNhomTieuChi) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maNhomTieuChi: param.maNhomTieuChi ? param.maNhomTieuChi.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.tenNhomTieuChi) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          tenNhomTieuChi: param.tenNhomTieuChi ? param.tenNhomTieuChi.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (this.filter.status || !this.filter.status) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      this.$axios.post(END_POINTS.NHOM_TIEU_CHI.DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachNhomTieuChi = response.data.data
          this.tongSoNhomTieuChi = response.data.totalCount || 0
        }
      })
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterdanhSachNhomTieuChi()
      })
    },
    refreshPage() {
      this.filter = {
        maNhomTieuChi: '',
        tenNhomTieuChi: '',
        ghiChu: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.nhomTieuChi.$refs.vbTables.reset()
      this.filterdanhSachNhomTieuChi()
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm nhóm tiêu chí'
      this.isCreate = true
      this.$refs.commonModal.showModal()
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    onSave() {
      if (this.isCreate) {
        this.$axios.post(END_POINTS.NHOM_TIEU_CHI.THEM_MOI, this.$trimObject(this.form)).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachNhomTieuChi()
            this.$refs.commonModal.hideModal()
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      } else {
        this.$axios.put(END_POINTS.NHOM_TIEU_CHI.SUA, this.$trimObject(this.form)).then(res => {
          if (res.data?.succeeded) {
            this.filterdanhSachNhomTieuChi()
            this.$refs.commonModal.hideModal()
            this.errorMessage = ''
          } else {
            this.errorMessage = res.data.message
          }
        })
      }
    },
    onUpdate(item) {
      this.isShow = true
      this.form = {
        id: item.id,
        maNhomTieuChi: item.maNhomTieuChi,
        tenNhomTieuChi: item.tenNhomTieuChi,
        ghiChu: item.ghiChu,
        status: item.status,
      }
      this.title = 'Cập nhật nhóm tiêu chí'
      this.isCreate = false
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.form)
        this.$refs.commonModal.showModal()
      })
    },
    onDelete() {
      this.isShow = false
      const payload = {
        listId: this.selectedRows.map(row => row.id),
      }
      this.$axios.delete(END_POINTS.NHOM_TIEU_CHI.XOA, payload, false).then(res => {
        if (res.data?.succeeded) {
          if (this.danhSachNhomTieuChi.length === 1) {
            const currentPage = this.$refs.nhomTieuChi.getCurrentPage()
            const index = currentPage.pageNumber - 1
            this.$refs.nhomTieuChi.resetCurrentPage(index === 0 ? 1 : index)
          }
          this.filterdanhSachNhomTieuChi()
          checDeleteMultiple(this, res.data)
        }
      })
    },
    setItemDelete(item) {
      this.selectedRows = [item]
      this.$refs.confirmDelete.showModal()
    },
    resetForm() {
      this.form = {
        maNhomTieuChi: '',
        tenNhomTieuChi: '',
        ghiChu: '',
        status: true,
      }
      this.errorMessage = ''
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['nhomMauPhieu'].includes(field)) {
          this.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
